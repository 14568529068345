import types from './mutation-types';

export default {
  [types.SET_LATEST_ROUTE](state, route) {
    state.route = route;
  },
  [types.UPDATE_COLUMNS](state, columns) {
    state.columns = columns;
  },
  [types.UPDATE_SEARCH](state, search) {
    state.search = search;
  },
  [types.UPDATE_SELECTED_ROW_SPACING_OPTION](state, rowSpacing) {
    state.selectedRowSpacingOption = rowSpacing;
  },
  [types.UPDATE_TOTAL_ROWS_COUNT](state, totalRowsCount) {
    state.totalRowsCount = totalRowsCount;
  },
  [types.SET_OPERATORS_LIST](state, data) {
    state.operatorsList = data;
  },

  [types.SET_PREVIOUS_EVENT_LIST_OPTIONS](state, previousEventListOptions) {
    state.previousEventListOptions = previousEventListOptions;
  },
  [types.UPDATE_EVENT_LIST_FIELDS](state, fields) {
    state.eventListFields = fields;
  },
  [types.UPDATE_EVENT_LIST](state, list) {
    state.eventList = list;
  },
  [types.UPDATE_EVENT_LIST_LOADING](state, loading) {
    state.eventListLoading = loading;
  },
  [types.UPDATE_SELECTED_EVENTS](state, events) {
    state.selectedEvents = events;
  },
  [types.UPDATE_SELECTED_MARKETS](state, events) {
    state.selectedMarkets = events;
  },
  [types.IS_EVENTS_SIDEBAR_ACTIVE](state, isActive) {
    state.isEventsSidebarActive = isActive;
  },
  [types.IS_EVENTS_FILTERS_ACTIVE](state, isActive) {
    state.isEventsFiltersActive = isActive;
  },

  [types.SET_SIDEBAR_EXPANDED](state, expanded) {
    state.sidebar.expanded = expanded;
  },
  [types.SET_SIDEBAR_LOADING](state, loading) {
    state.sidebar.loading = loading;
  },
  [types.SET_SIDEBAR_SPORTS](state, sports) {
    state.sidebar.sports = sports;
  },
  [types.SET_SIDEBAR_SPORTS_LOADING](state, loading) {
    state.sidebar.sportsLoading = loading;
  },
  [types.SET_SIDEBAR_REGIONS](state, regions) {
    state.sidebar.regions = regions;
  },
  [types.SET_SIDEBAR_COMPETITIONS](state, competitions) {
    state.sidebar.competitions = competitions;
  },
  [types.SET_SIDEBAR_SELECTION](state, selection) {
    state.sidebar.selection = selection;
  },

  [types.SET_SUBSCRIPTION_MODAL_OPEN](state, open) {
    state.subscriptionModal.open = open;
  },
  [types.SET_SUBSCRIPTION_MODAL_TYPE](state, type) {
    state.subscriptionModal.type = type;
  },
  [types.SET_SUBSCRIPTION_MODAL_ID](state, id) {
    state.subscriptionModal.id = id;
  },

  [types.UPDATE_EVENT](state, event) {
    state.event = event;
  },
  [types.UPDATE_EVENT_LOADING](state, loading) {
    state.eventLoading = loading;
  },
  [types.UPDATE_EVENT_MARKETS](state, eventMarkets) {
    state.eventMarkets = eventMarkets;
  },
  [types.UPDATE_EVENT_MARKETS_LOADING](state, loading) {
    state.eventMarketsLoading = loading;
  },

  [types.UPDATE_SELECTED_TEMPLATE_MARKET](state, payload) {
    state.marketTemplate = payload;
  },
  [types.UPDATE_SPORT_TEMPLATES](state, sportTemplates) {
    state.sportTemplates = sportTemplates;
  },
  [types.UPDATE_COMPETITION_TEMPLATE_CHANGE](state, value) {
    state.competitionTemplateChanged = value;
  },
  [types.UPDATE_JWT_TOKEN](state, value) {
    state.userToken = value;
  },
  [types.UPDATE_USERNAME](state, value) {
    state.username = value;
  },
  [types.UPDATE_USER_ROLES](state, value) {
    state.userRoles = value;
  },
  [types.UPDATE_OPERATOR](state, operator) {
    state.operator = operator;
  },
  [types.UPDATE_LOGIN_ERROR_MESSAGE](state, message) {
    state.loginErrorMessage = message;
  },
  [types.UPDATE_SOCKET_CONNECTION](state, message) {
    state.socketConnection = message;
  },
  [types.SAVE_EVENT_SUGGESTED_MAPPINGS](state, payload) {
    state.eventSuggestedMappings = payload;
  },
  [types.SAVE_COMPETITOR_SUGGESTED_MAPPINGS](state, payload) {
    state.competitorSuggestedMappings = payload;
  },
  [types.SAVE_REGION_SUGGESTED_MAPPINGS](state, payload) {
    state.regionSuggestedMappings = payload;
  },
  [types.SAVE_COMPETITION_SUGGESTED_MAPPINGS](state, payload) {
    state.competitionSuggestedMappings = payload;
  },
  [types.SAVE_PLAYER_SUGGESTED_MAPPINGS](state, payload) {
    state.playerSuggestedMappings = payload;
  },
  [types.SAVE_MAPPING_EVENTS](state, payload) {
    state.mappingEvents = payload;
  },
  [types.SAVE_MAPPING_COMPETITORS](state, payload) {
    state.mappingCompetitors = payload;
  },
  [types.SAVE_MAPPING_REGIONS](state, payload) {
    state.mappingRegions = payload;
  },
  [types.SAVE_MAPPING_COMPETITIONS](state, payload) {
    state.mappingCompetitions = payload;
  },
  [types.SAVE_MAPPING_PLAYERS](state, payload) {
    state.mappingPlayers = payload;
  },
  [types.UPDATE_CHANGE_PASSWORD_ATTRIBUTES](state, payload) {
    state.changePasswordAttributes = payload;
  },

  [types.UPDATE_MAPPING_LOADING](state, payload) {
    state.mappingLoading = payload;
  },
  [types.SAVE_ALL_FEEDS](state, payload) {
    state.allFeeds = payload;
  },
  [types.SET_SUSPENDING_EVENT_IDS](state, payload) {
    state.suspensionInProgressEventIds = payload;
  },
  [types.SET_SUSPEND_MARKET_TYPE_ACTION_LOADING](state, loading) {
    state.suspendMarketTypeActionLoading = loading;
  },
  [types.SET_EVENT_LIST_MAPPING_EVENT](state, eventData) {
    state.eventListMappingEvent = eventData;
  },
  [types.SET_MARKET_GROUPS](state, marketGroups) {
    state.marketGroups = marketGroups;
  },
  [types.SET_MARKET_FAVORITES](state, favorites) {
    state.marketFavorites = favorites;
  },

  [types.SET_MAPPING_INITIALIZED](state, initialized) {
    state.mapping.initialized = initialized;
  },
  [types.SET_MAPPING_LOADING](state, loading) {
    state.mapping.loading = loading;
  },
  [types.SET_MAPPING_SPORTS](state, sports) {
    state.mapping.sports = sports;
  },
  [types.SET_MAPPING_ENTRIES](state, entries) {
    state.mapping.entries = entries;
  },
  [types.SET_MAPPING_SELECTED_SPORT](state, { id, label }) {
    state.mapping.selectedSportId = id;
    state.mapping.selectedSportLabel = label;
  },
  [types.SET_MAPPING_PRIMARY_FEEDS](state, primaryFeeds) {
    state.mapping.primaryFeeds = primaryFeeds;
  },
  [types.SET_MAPPING_SECONDARY_FEEDS](state, secondaryFeeds) {
    state.mapping.secondaryFeeds = secondaryFeeds;
  },
  [types.SET_MAPPING_SELECTED_FEED](state, selectedFeed) {
    state.mapping.selectedFeed = selectedFeed;
  },
  [types.SET_MAPPING_SELECTED_SEARCH_OPTION](state, selectedSearchOption) {
    state.mapping.selectedSearchOption = selectedSearchOption;
  },
  [types.SET_MAPPING_SEARCH_QUERY](state, searchQuery) {
    state.mapping.searchQuery = searchQuery;
  },
  [types.SET_MAPPING_SELECTED_DATE](state, selectedDate) {
    state.mapping.selectedDate = selectedDate;
  },
  [types.SET_MAPPING_DISPLAY_STATE](state, displayState) {
    state.mapping.selectedDisplay = displayState;
  },
  [types.SET_MAPPING_FEEDS_PER_SPORT](state, feeds) {
    state.mapping.feedsPerSport = feeds;
  },
  [types.SET_MAPPING_DISPLAYED_FEEDS](state, feeds) {
    state.mapping.displayedFeeds = feeds;
  },
  [types.SET_MANUAL_RESULTING_EVENT](state, event) {
    state.manualResultingEvent = event;
  },
  [types.SET_MANUAL_RESULTING_EVENT_LOADING](state, loading) {
    state.manualResultingEventLoading = loading;
  },
  [types.SET_MANUAL_RESULTING_EVENTS](state, manualResultingEvents) {
    state.manualResultingEvents = manualResultingEvents;
  },
  [types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS](state, markets) {
    state.manualResultingEventMarkets = markets;
  },
  [types.UPDATE_MANUAL_RESULTING_MARKETS_LOADING](state, loading) {
    state.manualResultingEventMarketsLoading = loading;
  },

  // Player setup
  [types.SET_PLAYER_SETUP_TABLE_DATA](state, data) {
    state.playerSetupTableData = data;
  },
  [types.SET_PLAYER_SETUP_MAPPED_PLAYERS_FOR_PROJECTIONS](state, data) {
    state.playerSetupMappedPlayersForProjections = data;
  },
  [types.SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA](state, data) {
    state.playerSetupOriginalTableData = data;
  },
  [types.SET_PLAYER_SETUP_DATA](state, data) {
    state.playerSetupData = data;
  },
  [types.SET_IS_PLAYER_SETUP_DATA_LOADING](state, isLoading) {
    state.isPlayerSetupDataLoading = isLoading;
  },
  [types.SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE](state, available) {
    state.isPlayerSetupProjectionsAvailable = available;
  },
  [types.SET_IS_PLAYER_SETUP_SCOREBOARD_ACTIVE](state, isActive) {
    state.isPlayerSetupScoreboardActive = isActive;
  },
  [types.SET_PLAYER_SETUP_EVENT_SEARCH_ACTIVE](state, isActive) {
    state.playerSetupEventSearchActive = isActive;
  },
  [types.SET_PLAYER_SETUP_EVENT_LIST](state, data) {
    state.playerSetupEventList = data;
  },
  [types.SET_PLAYER_SETUP_EVENT_LIST_LOADING](state, isLoading) {
    state.playerSetupEventListLoading = isLoading;
  },
  [types.SET_TEAM_SQUAD_LIST_LOADING](state, isLoading) {
    state.teamSquadListLoading = isLoading;
  },
  [types.SET_IS_PLAYER_SETUP_SUBMIT_ENABLED](state, isEnabled) {
    state.isPlayerSetupSubmitEnabled = isEnabled;
  },
  [types.SET_IS_PLAYER_SETUP_SIMULATE_ENABLED](state, isEnabled) {
    state.isPlayerSetupSimulateEnabled = isEnabled;
  },
  [types.SET_NUMBER_OF_SIMULATE_ATTEMPTS](state, attempts) {
    state.numberOfSimulateAttempts = attempts;
  },
  [types.SET_IS_PLAYER_SETUP_PAGE_FROZEN](state, isFrozen) {
    state.isPlayerSetupPageFrozen = isFrozen;
  },
  [types.SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN](state, isFrozen) {
    state.isPlayerSetupPageSimulateBtnFrozen = isFrozen;
  },
  [types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE](state, message) {
    state.frozenPlayerSetupPageMessage = message;
  },
  [types.SET_IS_PLAYER_SETUP_SIMULATE_BY_SOCKET_CHANGE_ENABLED](state, isEnabled) {
    state.isPlayerSetupSimulateBySocketChangeEnabled = isEnabled;
  },
  [types.SET_AVAILABLE_MARKETS](state, data) {
    state.availableMarkets = data;
  },
  [types.SET_IS_MARKETS_LOADING](state, isLoading) {
    state.isMarketsLoading = isLoading;
  },
  [types.UPDATE_EVENT_TEAM_PLAYERS](state, { team, players }) {
    state.eventTeamPlayers[team] = players;
  },
  [types.SET_PLAYERS_FOR_MARKETS_POPUP_LIST](state, players) {
    state.playersForMarketsPopupList = players;
  },
  [types.SET_PLAYER_SETUP_HIGHLIGHTED_PLAYER](state, player) {
    state.highlightedPlayer = player;
  },
  [types.SET_PLAYER_SETUP_INFER_ACTIVE](state, isActive) {
    state.playerSetupInferActive = isActive;
  },
  [types.SET_PLAYER_SETUP_BEFORE_INFER_STATE](state, data) {
    state.playerSetupBeforeInferState = data;
  },
  [types.SET_PLAYER_PARAMS_SELECTED_MODE](state, selectedMode) {
    state.playerParamsSelectedMode = selectedMode;
  },
  [types.SET_PARAMS_MANAGER_MARKETS](state, markets) {
    state.paramsManagerMarkets = markets;
  },
  [types.SET_PARAMS_MANAGER_MARKETS_BEFORE_INFER_STATE](state, markets) {
    state.paramsManagerMarketsBeforeInferState = markets;
  },
  [types.SET_PARAMS_MANAGER_LINEUP_PRESETS_BY_TEAM](state, data) {
    state.lineupPresetsByTeam = data;
  },
  [types.SET_PARAMS_MANAGER_SELECTED_LINEUP_PRESET_BY_TEAM](state, data) {
    state.selectedLineupPresetByTeam = data;
  },
  [types.SET_PARAMS_MANAGER_SQUAD_DATA](state, data) {
    state.paramsManagerSquadData = data;
  },
  [types.SET_PARAMS_MANAGER_SUSPEND_LOADING](state, isLoading) {
    state.paramsManagerEventSuspendLoading = isLoading;
  },
  [types.SET_AVAILABLE_MARKETS_PLAYER_TYPES](state, data) {
    state.availableMarketsPlayerTypes = data;
  },
  [types.SET_PLAYER_SETUP_UNPUBLISHED_CHANGES](state, unpublishedChanges) {
    state.playerSetupUnpublishedChanges = unpublishedChanges;
  },
  [types.SET_ALL_PLAYERS_SUSPEND_TOGGLE_ACTIVE](state, isActive) {
    state.allPlayersSuspendToggleActive = isActive;
  },

  // Game params
  [types.SET_PLAYER_SETUP_GAME_PARAMS](state, params) {
    state.playerSetupGameParams = params;
  },
  [types.SET_GAME_PARAMS_SELECTED_MODE](state, selectedMode) {
    state.gameParamsSelectedMode = selectedMode;
  },

  [types.SET_GAME_PARAMS_LAST_SUBMITTED_SELECTED_MODE](state, selectedMode) {
    state.gameParamsLastSubmittedSelectedMode = selectedMode;
  },
  [types.SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE](state, projectionAvailable) {
    state.paramsManagerGameProjectionsAvailable = projectionAvailable;
  },
  [types.SET_ALL_BASEBALL_PARKS](state, allBaseballParks) {
    state.allBaseballParks = allBaseballParks;
  },

  [types.SET_GAME_PARAMS_SELECTED_MARKETS](state, selectedMarkets) {
    state.gameParamsSelectedMarkets = selectedMarkets;
  },
  [types.SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING](state, processing) {
    state.gameParamsSelectedMarketsProcessing = processing;
  },

  // Trading UI
  [types.SET_TRADING_EVENT](state, event) {
    state.tradingEvent = event;
  },
  [types.SET_TRADING_EVENT_LOADING](state, loading) {
    state.tradingEventLoading = loading;
  },
  [types.SET_ORIGINAL_TRADING_PLAYER_PROPS](state, data) {
    state.originalTradingPlayerProps = data;
  },
  [types.SET_LAST_SIMULATE_TRADING_PLAYER_PROPS](state, data) {
    state.lastSimulateTradingPlayerProps = data;
  },
  [types.SET_TRADING_PLAYER_PROPS](state, data) {
    state.tradingPlayerProps = data;
  },
  [types.SET_IS_SIMULATE_BTN_ENABLED](state, isEnabled) {
    state.isSimulateBtnEnabled = isEnabled;
  },
  [types.SET_IS_SIMULATE_BTN_LOADING](state, isLoading) {
    state.isSimulateBtnLoading = isLoading;
  },
  [types.SET_IS_PUBLISH_BTN_ENABLED](state, isEnabled) {
    state.isPublishBtnEnabled = isEnabled;
  },
  [types.SET_IS_PUBLISH_BTN_LOADING](state, isLoading) {
    state.isPublishBtnLoading = isLoading;
  },
  [types.SET_TRADING_PLAYER_PROPS_LOADING](state, isLoading) {
    state.tradingPlayerPropsLoading = isLoading;
  },
  [types.SET_TRADING_EVENT_SEARCH_ACTIVE](state, isActive) {
    state.tradingEventSearchActive = isActive;
  },
  [types.SET_TRADING_EVENT_LIST](state, data) {
    state.tradingEventList = data;
  },
  [types.SET_TRADING_EVENT_LIST_LOADING](state, isLoading) {
    state.tradingEventListLoading = isLoading;
  },
  [types.SET_TRADING_EVENT_MARKETS](state, markets) {
    state.tradingEventMarkets = markets;
  },
  [types.UPDATE_SINGLE_TRADING_EVENT_MARKET](state, market) {
    state.tradingEventMarkets[market.marketId] = market;
  },
  [types.SET_TRADING_EVENT_SUSPEND_LOADING](state, isLoading) {
    state.tradingEventSuspendLoading = isLoading;
  },
  [types.SET_IS_TRADING_UI_PAGE_FROZEN](state, isFrozen) {
    state.isTradingUIPageFrozen = isFrozen;
  },
  [types.SET_IS_TRADING_UI_PAGE_SIMULATE_BTN_FROZEN](state, isFrozen) {
    state.isTradingUIPageSimulateBtnFrozen = isFrozen;
  },
  [types.SET_IS_UNSAVED_CHANGES_ACTIVE](state, isActive) {
    state.isUnsavedChangesActive = isActive;
  },
  [types.SET_SELECTED_TRADING_MARKETS](state, markets) {
    state.selectedTradingMarkets = markets;
  },
  [types.UPDATE_SELECTED_ODDS_FORMAT](state, oddsFormat) {
    state.selectedOddFormat = oddsFormat;
  },
  [types.SET_TRADING_SQUAD](state, data) {
    state.tradingSquad = data;
  },
  [types.SET_TRADING_GAME_PARAMS_LOADING](state, isLoading) {
    state.tradingGameParamsLoading = isLoading;
  },
  [types.SET_TRADING_EVENT_FEEDS](state, feeds) {
    state.tradingEventFeeds = feeds;
  },
  [types.SET_TRADING_EVENT_SELECTED_FEED](state, selectedFeed) {
    state.tradingEventSelectedFeed = selectedFeed;
  },
  [types.SET_IS_TRADING_SIMULATE_BY_SOCKET_CHANGE_ENABLED](state, isEnabled) {
    state.tradingSimulateBySocketChangeEnabled = isEnabled;
  },

  // Manual edit
  [types.UPDATE_MANUAL_EDIT_SELECTED_SPORT](state, selectedSport) {
    state.manualEditSelectedSport = selectedSport;
  },
  [types.UPDATE_MANUAL_EDIT_TABLE_COLUMNS](state, columns) {
    state.manualEditTableColumns = columns;
  },
  [types.UPDATE_MANUAL_EDIT_TABLE_DATA](state, data) {
    state.manualEditTableData = data;
  },
  [types.UPDATE_MANUAL_EDIT_SELECTED_ROW](state, row) {
    state.manualEditSelectedRow = row;
  },
  [types.UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING](state, isLoading) {
    state.manualEditDataLoading = isLoading;
  },
  [types.UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST](state, data) {
    state.manualEditPlayerSearchList = data;
  },
  [types.UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST_LOADING](state, isLoading) {
    state.manualEditPlayerSearchListLoading = isLoading;
  },
  [types.UPDATE_MANUAL_EDIT_SEARCH](state, searchValue) {
    state.manualEditSearch = searchValue;
  },
  [types.UPDATE_MANUAL_EDIT_SELECTED_FEED](state, feed) {
    state.manualEditSelectedFeed = feed;
  },
  [types.UPDATE_MANUAL_EDIT_IS_CREATE_NEW_RECORD_ACTIVE](state, isActive) {
    state.manualEditIsCreateNewRecordActive = isActive;
  },
  [types.SET_DISPLAY_MARKETS_CONFIGURATION](state, marketsConfiguration) {
    state.displayMarketsConfiguration = marketsConfiguration;
  },
  [types.SET_ALL_MARKET_PARAMETERS](state, marketParameters) {
    state.allMarketParameters = marketParameters;
  },
  [types.SET_ALL_SELECTION_PARAMETERS](state, selectionParameters) {
    state.allSelectionParameters = selectionParameters;
  },
  // Multiview
  [types.SET_MULTIVIEW_LIST_LATEST_OPTIONS](state, latestOptions) {
    state.multiviewList.latestOptions = latestOptions;
  },
  [types.SET_MULTIVIEW_LIST_LOADING](state, loading) {
    state.multiviewList.loading = loading;
  },
  [types.SET_MULTIVIEW_LIST_EVENTS_BY_ID](state, eventsById) {
    state.multiviewList.eventsById = eventsById;
  },
  [types.SET_MULTIVIEW_LIST_COMPETITIONS_BY_ID](state, competitionsById) {
    state.multiviewList.competitionsById = competitionsById;
  },
  [types.SET_MULTIVIEW_LIST_MARKETS](state, markets) {
    state.multiviewList.markets = markets;
  },
  [types.UPDATE_SINGLE_MULTIVIEW_LIST_MARKET](state, { eventId, market }) {
    state.multiviewList.markets[`${eventId}_${market.marketId}`] = market;
  },
  [types.SET_MULTIVIEW_DRAWER_EVENT](state, event) {
    state.multiviewDrawer.event = event;
  },
  [types.SET_MULTIVIEW_DRAWER_LOADING](state, loading) {
    state.multiviewDrawer.loading = loading;
  },
  [types.SET_MULTIVIEW_DRAWER_PROCESSING](state, processing) {
    state.multiviewDrawer.processing = processing;
  },
  [types.SET_MULTIVIEW_ORIGINAL_PLAYER_PARAMS](state, data) {
    state.multiviewOriginalPlayerParams = data;
  },
  [types.SET_MULTIVIEW_PLAYER_PARAMS](state, data) {
    state.multiviewPlayerParams = data;
  },
  [types.SET_MULTIVIEW_PLAYER_PARAMS_LOADING](state, isLoading) {
    state.multiviewPlayerParamsLoading = isLoading;
  },
  [types.SET_MULTIVIEW_LAST_SIMULATE_PLAYER_PARAMS](state, data) {
    state.multiviewLastSimulatePlayerParams = data;
  },
  [types.SET_MULTIVIEW_SQUAD](state, data) {
    state.multiviewSquad = data;
  },
  [types.SET_MULTIVIEW_SIMULATE_BTN_ENABLED](state, isEnabled) {
    state.multiviewSimulateBtnEnabled = isEnabled;
  },
  [types.SET_MULTIVIEW_SIMULATE_BTN_FROZEN](state, isFrozen) {
    state.multiviewSimulateBtnFrozen = isFrozen;
  },
  [types.SET_MULTIVIEW_SIMULATE_BTN_LOADING](state, isLoading) {
    state.multiviewSimulateBtnLoading = isLoading;
  },
  [types.SET_MULTIVIEW_SUBMIT_BTN_ENABLED](state, isEnabled) {
    state.multiviewSubmitBtnEnabled = isEnabled;
  },
  [types.SET_MULTIVIEW_SUBMIT_BTN_LOADING](state, isLoading) {
    state.multiviewSubmitBtnLoading = isLoading;
  },
  [types.SET_IS_MULTIVIEW_DRAWER_FROZEN](state, isFrozen) {
    state.multiviewDrawerFrozen = isFrozen;
  },
  [types.SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS](state, numberOfAttempts) {
    state.multiviewNumberOfSimulateAttempts = numberOfAttempts;
  },
  [types.SET_IS_MULTIVIEW_SIMULATE_BY_SOCKET_CHANGE_ENABLED](state, isEnabled) {
    state.multiviewSimulateBySocketChangeEnabled = isEnabled;
  },
  [types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID](state, event) {
    state.multiviewList.eventsById[event.eventId] = event;
  },
  [types.SET_MULTIVIEW_LIST_PARAMS_LOADING](state, isLoading) {
    state.multiviewList.paramsLoading = isLoading;
  },

  // Market group
  [types.UPDATE_MARKET_GROUP_MARKET_CODE_LIST](state, marketCodes) {
    state.marketGroupMarketCodeSearchList = marketCodes;
  },
  [types.UPDATE_MARKET_GROUP_MARKET_CODE_LIST_LOADING](state, isLoading) {
    state.marketGroupMarketCodeSearchListLoading = isLoading;
  },

  // Odds checker
  [types.UPDATE_ODDS_CHECKER_FILTERS_META](state, { sports, competitions }) {
    if (!sports) {
      state.oddsCheckerFiltersMeta.competitions = competitions;
      return;
    }
    state.oddsCheckerFiltersMeta = {
      sports,
      competitions,
    };
  },
  [types.UPDATE_ODDS_CHECKER_EVENTS](state, events) {
    state.oddsCheckerEvents = events;
  },
  [types.UPDATE_ODDS_CHECKER_EVENT_BY_ID](state, event) {
    state.oddsCheckerEvents[event.eventId] = event;
  },
  [types.UPDATE_ODDS_CHECKER_MARKETS](state, markets) {
    state.oddsCheckerMarkets = markets;
  },
  [types.UPDATE_ODDS_CHECKER_MARKET_BY_ID](state, { eventId, market, deleteMarket }) {
    if (deleteMarket) {
      delete state.oddsCheckerMarkets[`${eventId}_${market.marketId}`];
      return;
    }
    state.oddsCheckerMarkets[`${eventId}_${market.marketId}`] = market;
  },
  [types.SET_ODDS_CHECKER_EVENTS_LOADING](state, isLoading) {
    state.oddsCheckerEventsLoading = isLoading;
  },
  [types.UPDATE_ODDS_CHECKER_QUERY_OPTIONS](state, queryOptions) {
    state.oddsCheckerQueryOptions = queryOptions;
  },
  [types.SET_SELECTED_ODDS_CHECKER_MARKET](state, market) {
    state.selectedOddsCheckerMarket = market;
  },

  // Audit log
  [types.SET_AUDIT_LOG_EVENT](state, event) {
    state.auditLogEvent = event;
  },
  [types.SET_AUDIT_LOG_EVENT_LOADING](state, loading) {
    state.auditLogEventLoading = loading;
  },
  [types.SET_AUDIT_LOG_EVENT_MARKETS](state, markets) {
    state.auditLogEventMarkets = markets;
  },
  [types.SET_MARKET_HISTORY_SELECTIONS_URLS](state, urls) {
    state.auditLogEventMarketSelectionsUrls = urls;
  },
  [types.SET_AUDIT_LOG_MARKET_HISTORY_DASHBOARD_LOADING](state, loading) {
    state.auditLogMarketHistoryDashboardLoading = loading;
  },

  // market templates scroll position
  [types.SET_MARKET_TEMPLATES_SCROLL_POSITION](state, value) {
    state.marketTemplateMarketsScrollPosition = value;
  },

  // Competiton subscriptions
  [types.UPDATE_COMPETITIONS_SUBSCRIPTIONS_FILTERS](state, data) {
    state.competitionsSubscriptionsFilters = data;
  },

  // Client settings
  [types.TOGGLE_CLIENT_SETTINGS_MODAL](state, value) {
    state.clientSettingsModalOpened = value;
  },
};
